<template>
  <v-skeleton-loader
    :loading="!isReady && !isInitialLoad"
    type="table"
  >
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="isLoading"
      :loading-text="$t('ConfigList.loading')"
      :options.sync="serverOptions"
      :server-items-length="totalItemCount"
      :footer-props="{'items-per-page-options':[10, 25, 50, 100]}"
      :show-expand="showExpand"
      @click:row="onRowClick"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-text-field
              v-if="hasSearch"
              v-model="searchQuery"
              :label="$t('General.search')"
              class="mx-4"
              filled
              clearable
              prepend-inner-icon="far fa-search"
              hide-details='auto'
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
    </v-data-table>
  </v-skeleton-loader>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: "ApiTable",
    props: {
      headers: {
        type: Array,
        required: true
      },
      apiEndpoint: {
        type: String,
        required: false
      },
      apiParameters: {
        type: Object,
        required: false
      },
      showExpand: {
        type: Boolean,
        required: false,
        default: false
      },
      loading: {
        type: Boolean,
        required: false,
        default: true
      },
      reload: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    mounted() {
      this.reloadData();
    },
    data() {
      return {
        items: [],
        isLoading: true,
        isInitialLoad: true,
        serverOptions: {},
        totalItemCount: null,
        searchQuery: ''
      }
    },
    computed: {
      isReady() {
        return this.user !== null
      },
      columnSlots() {
        return Object.keys(this.$scopedSlots)
          .filter(key => (key.indexOf('item.') === 0))
          .reduce((obj, key) => {
            obj[key] = this.$scopedSlots[key];
            return obj;
          }, {})
      },
      ...mapState({
        user: state => state.user.userProfile
      }),
      hasSearch () {
        return this.headers.find(header => header.filterable);
      }
    },
    watch: {
      loading(val) {
        if(val) {
          this.reloadData()
        }
      },
      isReady(val) {
        if (val) {
          this.reloadData()
        }
      },
      serverOptions: {
        handler () {
          this.reloadData();
        },
        deep: true
      },
      apiEndpoint() {
        this.reloadData();
      },
      apiParameters: {
        handler () {
          this.reloadData();
        },
        deep: true
      },
      
      searchQuery (val) {
        val
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.reloadData();
        }, 600);
      }
    },
    methods: {
      reloadData() {
        if (!this.isReady) {
          return;
        }

        // Set loading state
        this.isLoading = true;
        this.$emit('update:loading', true);

        // If no API endpoint is set, we assume the parent is still loading data
        if (!this.apiEndpoint) {
          return true;
        }

        // Build query parameters
        const { sortBy, sortDesc, page, itemsPerPage } = this.serverOptions;
        let queryParams = Object.assign({}, this.apiParameters, {
          page: page,
          itemsPerPage: itemsPerPage
        });
        for(let i = 0; i<sortBy.length; i++) {
          queryParams['order[' + sortBy[i] + ']'] = sortDesc[i] ? 'desc' : 'asc';
        }

        if (this.searchQuery) {
          // API-platform doesn't support field A *or* field B, so now we only search the *first* filterable field
          let header = this.headers.find(header => header.filterable);
          queryParams[header.value] = this.searchQuery
        }

        this.$apiClient.get(this.apiEndpoint, {params: queryParams}).then(response => {
          this.items = response.data['hydra:member'];
          this.isLoading = false;
          this.isInitialLoad = false;
          this.totalItemCount = response.data['hydra:totalItems'];
          this.$emit('requestcomplete', response);
        }).catch(e => {
          this.isLoading = false;
          this.$emit('requesterror', e);
        }).finally(() => {
          this.$emit('update:loading', false)
        })
      },
      onRowClick(item) {
        this.$emit('rowclick', item);
      }
    }
  }
</script>

<style scoped>

</style>
