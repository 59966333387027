var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ConfirmationDialog',{attrs:{"title":_vm.$t('ConfigList.delete.title')},on:{"confirm":function($event){return _vm.deleteTemplate(_vm.deleteConfirmation.id)}},model:{value:(_vm.deleteConfirmation.show),callback:function ($$v) {_vm.$set(_vm.deleteConfirmation, "show", $$v)},expression:"deleteConfirmation.show"}},[_vm._v(" "+_vm._s(_vm.$t('ConfigList.delete.text', [_vm.deleteConfirmation.name]))+" ")]),_c('v-card',{staticClass:"mb-10",attrs:{"tile":""}},[_c('v-card-title',[_c('h3',{staticClass:"pr-1"},[_vm._v(_vm._s(_vm.$t('General.manage')))]),_c('h3',[_c('b',[_vm._v(_vm._s(_vm.$t('ConfigList.configurations')))])])]),_c('ApiTable',{attrs:{"headers":_vm.headers,"apiEndpoint":_vm.apiEndpoint,"loading":_vm.reload},on:{"update:loading":function($event){_vm.reload=$event},"rowclick":_vm.onRowClick},scopedSlots:_vm._u([{key:"footer.prepend",fn:function(){return [_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":_vm.editing,"fab":"","small":"","color":"primary"},on:{"click":function($event){_vm.addingTemplate = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){_vm.editing = !_vm.editing}}},[_c('v-icon',[_vm._v(_vm._s(_vm.editing ? 'mdi-close' : 'mdi-pencil'))])],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[(item.id == _vm.nameEditor.id)?_c('v-text-field',{staticClass:"my-0 py-0",attrs:{"rules":[
                                function (v) { return v.length > 0 || _vm.$t('General.required'); },
                                function (v) { return v.length <= 255 || _vm.$t('Rules.maxLength', [v.length, 255]); }
                            ],"hide-details":"auto"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-slide-x-transition',[(_vm.nameEditor.new != _vm.nameEditor.old)?_c('v-icon',{on:{"click":function($event){_vm.nameEditor.new = _vm.nameEditor.old}}},[_vm._v(" mdi-refresh ")]):_vm._e()],1)]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-icon',{staticClass:"pt-1",attrs:{"disabled":_vm.nameEditor.new == _vm.nameEditor.old},on:{"click":_vm.saveName}},[_vm._v(" mdi-check ")]),_c('v-icon',{staticClass:"pt-1",on:{"click":_vm.closeNameEditor}},[_vm._v(" mdi-close ")])]},proxy:true}],null,true),model:{value:(_vm.nameEditor.new),callback:function ($$v) {_vm.$set(_vm.nameEditor, "new", $$v)},expression:"nameEditor.new"}}):_c('span',[_vm._v(_vm._s(item.name))])],1),_c('v-slide-x-transition',[_c('v-col',{staticClass:"my-auto",attrs:{"align":"right"}},[(!_vm.editing && _vm.isAdmin)?_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.downloadConfig(item)}}},[_vm._v(" mdi-download ")]):_vm._e(),(_vm.editing)?_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.openNameEditor(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.editing)?_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.onDuplicateClick(item)}}},[_vm._v(" mdi-content-copy ")]):_vm._e(),(_vm.editing)?_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.onDeleteClick(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)],1)],1)]}},{key:"item.organization",fn:function(ref){
                            var item = ref.item;
return [_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(item.organization)+" ")]),_c('v-col',{attrs:{"cols":"1"}})],1)]}}])}),_c('Dialog',{attrs:{"title":_vm.$t('ConfigList.new.title')},model:{value:(_vm.addingTemplate),callback:function ($$v) {_vm.addingTemplate=$$v},expression:"addingTemplate"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.form.valid),callback:function ($$v) {_vm.$set(_vm.form, "valid", $$v)},expression:"form.valid"}},[_c('v-text-field',{staticClass:"mt-n2",attrs:{"label":_vm.$t('ConfigList.new.name'),"rules":[
                        function (v) { return v.length > 0 || _vm.$t('General.required'); },
                        function (v) { return v.length <= 255 || _vm.$t('Rules.maxLength', [v.length, 255]); }
                    ]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('v-select',{staticClass:"mb-2",attrs:{"label":_vm.$t('ConfigList.new.copy'),"items":_vm.defaultTemplates,"item-text":"name","item-value":"name","rules":[
                        function (v) { return !!v || _vm.$t('General.required'); }
                    ]},model:{value:(_vm.selectedTemplateName),callback:function ($$v) {_vm.selectedTemplateName=$$v},expression:"selectedTemplateName"}}),_c('v-btn',{attrs:{"color":"confirm"},on:{"click":_vm.validateForm}},[_vm._v(" "+_vm._s(_vm.$t('ConfigList.new.create'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }