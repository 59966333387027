<template>
    <div>
        <ConfirmationDialog 
            v-model="deleteConfirmation.show"
            :title="$t('ConfigList.delete.title')"
            @confirm="deleteTemplate(deleteConfirmation.id)"
        >
            {{ $t('ConfigList.delete.text', [deleteConfirmation.name]) }}
        </ConfirmationDialog>
        <v-card tile class="mb-10">
            <v-card-title>
                <h3 class="pr-1">{{ $t('General.manage')}}</h3>
                <h3><b>{{ $t('ConfigList.configurations') }}</b></h3>
            </v-card-title>
            <ApiTable
                :headers="headers"
                :apiEndpoint="apiEndpoint"
                :loading.sync="reload"
                @rowclick="onRowClick"
            >
                <!-- eslint-disable-next-line -->
                <template v-slot:footer.prepend>
                    <v-btn
                        @click="addingTemplate = true"
                        :disabled="editing"
                        class="mr-2"
                        fab
                        small
                        color="primary"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                        @click="editing = !editing"
                        class="mr-2"
                        fab
                        small
                        color="primary"
                    >
                        <v-icon>{{editing ? 'mdi-close' : 'mdi-pencil'}}</v-icon>
                    </v-btn>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.name="{item}">
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-if="item.id == nameEditor.id"
                                v-model="nameEditor.new"
                                :rules="[
                                    v => v.length > 0 || $t('General.required'),
                                    v => v.length <= 255 || $t('Rules.maxLength', [v.length, 255])
                                ]"
                                hide-details="auto"
                                class="my-0 py-0"
                            >
                                <template v-slot:append>
                                    <v-slide-x-transition>
                                        <v-icon
                                            v-if="nameEditor.new != nameEditor.old"
                                            @click="nameEditor.new = nameEditor.old"
                                        >
                                            mdi-refresh
                                        </v-icon>
                                    </v-slide-x-transition>
                                </template>
                                <template v-slot:append-outer>
                                    <v-icon
                                        class="pt-1"
                                        :disabled="nameEditor.new == nameEditor.old"
                                        @click="saveName"
                                    >
                                        mdi-check
                                    </v-icon>
                                    <v-icon
                                        class="pt-1"
                                        @click="closeNameEditor"
                                    >
                                        mdi-close
                                    </v-icon>
                                </template>
                            </v-text-field>
                            <span v-else>{{ item.name }}</span>
                        </v-col>
                        <v-slide-x-transition>
                            <v-col 
                                align="right"
                                class="my-auto"
                            >
                                <v-icon 
                                    v-if="!editing && isAdmin"
                                    class="mr-2"
                                    @click.stop="downloadConfig(item)"
                                >
                                    mdi-download
                                </v-icon>
                                <v-icon 
                                    v-if="editing"
                                    class="mr-2"
                                    @click="openNameEditor(item)"
                                >
                                    mdi-pencil
                                </v-icon>
                                <v-icon 
                                    v-if="editing"
                                    class="mr-2"
                                    @click="onDuplicateClick(item)"
                                >
                                    mdi-content-copy
                                </v-icon>
                                <v-icon
                                    v-if="editing"
                                    class="mr-2"
                                    @click="onDeleteClick(item)"
                                >
                                    mdi-delete
                                </v-icon>
                            </v-col>
                        </v-slide-x-transition>
                    </v-row>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.organization="{item}">
                    <v-row>
                        <v-col>
                            {{ item.organization }}
                        </v-col>
                        <v-col cols="1">
                            
                        </v-col>
                    </v-row>
                </template>
            </ApiTable>
            <Dialog 
                v-model="addingTemplate"
                :title="$t('ConfigList.new.title')"
            >
                <v-form 
                    ref="form"
                    v-model="form.valid"
                    lazy-validation
                >
                    <v-text-field
                        v-model="form.name"
                        :label="$t('ConfigList.new.name')"
                        :rules="[
                            v => v.length > 0 || $t('General.required'),
                            v => v.length <= 255 || $t('Rules.maxLength', [v.length, 255])
                        ]"
                        class="mt-n2"
                    >
                    </v-text-field>
                    <v-select
                        v-model="selectedTemplateName"
                        :label="$t('ConfigList.new.copy')"
                        :items="defaultTemplates"
                        item-text="name"
                        item-value="name"
                        :rules="[
                            v => !!v || $t('General.required')
                        ]"
                        class="mb-2"
                    >
                    </v-select>
                    <v-btn
                        @click="validateForm"
                        color="confirm"
                    >   
                        {{ $t('ConfigList.new.create') }}
                    </v-btn>
                </v-form>
            </Dialog>
        </v-card>
    </div>
</template>

<script>
    import apiClient from "@/api/apiClient";
    import ApiTable from './ApiTable.vue'
    import Dialog from './dialogs/Dialog.vue'
    import ConfirmationDialog from './dialogs/ConfirmationDialog.vue'

    export default {
        components: { 
            ApiTable,
            Dialog,
            ConfirmationDialog
        },
        data: () => ({
            apiEndpoint: "/device-configuration-templates",
            addingTemplate: false,
            editing: false,
            nameEditor: {
                id: null,
                old: null,
                new: null
            },
            form: {
                valid: true,
                name: "",
                config: null
            },
            reload: false,
            deleteConfirmation: {
                show: false,
                id: null,
                name: null
            },
            selectedTemplateName: null
        }),
        computed: {
            defaultTemplates() {
                return this.$store.getters.defaultConfigs
            },
            isAdmin() {
                return this.$store.getters.isAdmin
            },
            headers() {
                let headers = [
                    {
                        text: "Name",
                        value: "name",
                        filterable: true
                    }
                ]
                // Only admins have access to organizations tab
                if(this.isAdmin) {
                    headers.push({
                        text: "Organization",
                        value: "organization",
                        filterable: true
                    })
                }
                return headers   
            }
        },
        methods: {
            onRowClick(template) {
                if(!this.editing) {
                    this.$router.push({name: 'template', params: { template_id: template.id }});
                }
            },
            onDuplicateClick(template) {
                let copyName = `${template.name} (copy)`.substring(0, 255)
                this.createTemplate(copyName, template.configuration)
            },
            onDeleteClick(template) {
                this.deleteConfirmation.id = template.id
                this.deleteConfirmation.name = template.name
                this.deleteConfirmation.show = true
            },
            validateForm() {
                if(this.$refs.form.validate()) {
                    this.createTemplate(this.form.name, this.form.config)
                    this.addingTemplate = false
                }
            },

            // template CRUD operations
            createTemplate(name, config) {
                let body = {
                    name: name,
                    organization: `/organizations/${this.$store.getters.activeOrganizationId}`,
                    configuration: config
                }
                apiClient.post('/device-configuration-templates', body)
                    .catch(e => {
                        // TODO snackbar if save failed
                        console.log(e)
                    })
                    .finally(() => {
                        this.reload = true
                    })
            },  
            deleteTemplate(templateId) {
                apiClient.delete(`/device-configuration-templates/${templateId}`)
                    .then(() => {
                        this.deleteConfirmation.id = this.deleteConfirmation.name = null
                    })
                    .catch(e => {
                        // TODO snackbar if save failed
                        console.log(e)
                    })
                    .finally(() => {
                        this.reload = true
                    })
            },

            openNameEditor(item) {
                this.nameEditor.id = item.id
                this.nameEditor.old = this.nameEditor.new = item.name
            },
            closeNameEditor() {
                this.nameEditor.id = this.nameEditor.new = this.nameEditor.old = null
            },
            saveName() {
                let templateId = this.nameEditor.id
                let body = {
                    name: this.nameEditor.new,
                }
                let headers = {
                    'Content-Type': 'application/merge-patch+json'
                }
                apiClient.patch(`/device-configuration-templates/${templateId}`, body, {headers})
                    .then(() => {
                        this.closeNameEditor()
                    })
                    .catch((err) => {
                        // TODO snackbar if save failed
                        console.error(err)
                    })
                    .finally(() => {
                        this.reload = true
                    })
            },
            downloadConfig(item) {
                let json = JSON.stringify(item.configuration)
                let fileName = item.name
                let element = document.createElement('a')
                element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(json));
                element.setAttribute('download', fileName);
                element.style.display = 'none'
                document.body.appendChild(element)
                element.click()
                document.body.removeChild(element)
            }
        },
        watch: {
            addingTemplate(val) {
                if(!val) {
                    this.selectedTemplateName = null
                    this.form.valid = true
                    this.form.name = ""
                    this.form.config = null
                    this.$refs.form.resetValidation()
                }    
            },
            editing() {
                this.closeNameEditor()
            },
            selectedTemplateName(value) {
                let template = this.defaultTemplates.find(template =>  template.name == value)
                template ? this.form.config = template.config : this.form.config = null
            }
        }
    }
</script>